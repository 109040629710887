<template>
  <div>
    <div class="tableHeader">
      <span class="titles">供应商:{{adjustmentData.providerName}}</span>
      <span class="period">考核周期:{{adjustmentData.assessCycle}}</span>
    </div>
    <global-table  :tableField="tableField" :tableData="suptableData"></global-table>
    <div class="total">累计金额:{{totalVolume}}</div>
  </div>
</template>

<script>
import GlobalTable from '@/components/actionList/GlobalTable.vue'
import { tableField } from '../js/Lifecycle'
import { request } from '@/assets/js/http.js'
export default {
  components: { GlobalTable },
  name: 'Supplementdialog',
  props: ['adjustmentData'],
  data () {
    return {
      tableField: tableField,
      suptableData: [],
      totalVolume: ''
    }
  },
  mounted () {
    this.SupplierLcmList()
  },
  methods: {
    SupplierLcmList () {
      const obj = {
        providerCode: this.adjustmentData.providerCode,
        assessCycle: this.adjustmentData.assessCycle
      }
      request('/api/order/order/getOrderInfo', 'get', obj).then((res) => {
        if (res.code === '200') {
          this.suptableData = res.data.orderInfo
          this.totalVolume = res.data.totalVolume
        }
      })
    }
  }
}
</script>
<style scoped>
.period{
  float: right;
}
.total{
  margin: 10px 80px;
  float: right;
}
.tableHeader{
  margin-bottom: 10px;
}
.tableList{
  padding: 0px;
}
</style>
